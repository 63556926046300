@import "../../variables.scss";

.directory-container {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    @media only screen and (min-width: $desktop-xl) {
        max-width: 1600px;
    }
}
