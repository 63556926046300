@import "../../colors.scss";
@import "../../mixins.scss";

.category-preview-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 0 20px;

  .title {
    font-size: 2rem;
    margin-bottom: 25px;
    padding-left: 8px;
  }

  .preview {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    column-gap: 20px;
    margin-top: 20px;
    @include for-size(tablet) {
      grid-template-columns: repeat(2, 1fr);
    }
    @include for-size(desktop) {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  .linkToFullProduct {
    margin-top: 40px;
    text-decoration: none;
    span {
      color: $flame;
      font-size: 1.4rem;
      box-shadow: inset 0 0 0 0 $electric-blue;
      margin: 0 -0.25rem;
      padding: 0 0.25rem;
      transition: all 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
      &:hover {
        font-size: 1.6rem;
        box-shadow: inset 300px 0 0 0 $electric-blue;
        color: $mine-shaft;
      }
    }
  }
}
