/* ============================================= */
/*         Colors                                */
/* ============================================= */

// base
$mine-shaft: rgb(55, 55, 55);

// main brand colors
$flame: #e25c39;
$electric-blue: #7cf2ef;
$torea-bay: #1818a0;
$laser-lemon: #fff35f;

// highlight colors
$magenta: #ff00ff;
$cyan: #00ffff;

$gray: rgb(145, 145, 145);
$black: rgb(16, 16, 16);
$white: rgb(248, 248, 248);

.app-container {
    background-color: $white;
}
