@import "../../mixins.scss";

.product-card-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 350px;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  padding: 0 10px 40px;
  @include for-size(tablet) {
    padding-bottom: 0px;
  }
  img {
    width: 100%;
    height: 95%;
    object-fit: cover;
    margin-bottom: 5px;
    padding: 0px;
  }

  button {
    width: 80%;
    opacity: 0.7;
    position: absolute;
    top: 205px;
    display: none;
    @include for-size(tablet) {
      padding-bottom: 0px;
      top: 255px;
    }
  }

  &:hover {
    img {
      opacity: 0.8;
    }

    button {
      opacity: 0.85;
      display: flex;
    }
  }

  .product-card-footer {
    width: 100%;
    height: 5%;
    display: flex;
    justify-content: space-between;
    font-size: 18px;

    .name {
      width: 90%;
      margin-bottom: 15px;
    }

    .price {
      width: 10%;
    }
  }
}
