@import "../../variables.scss";
@import "../../colors.scss";

.sign-in-form-container {
    display: flex;
    flex-direction: column;
    padding: 50px 40px 80px;
    max-width: 380px;
    box-sizing: border-box;
}

.buttons-container {
    display: flex;
    flex-direction: row;
    button {
        margin: 0 5px;
    }
}
