/* ============================================= */
/*         Home Page Base Styles                 */
/* ============================================= */

@import "../../../variables.scss";

.main-content-wrapper {
    position: relative;
    overflow-x: hidden;
}

.site-bg {
    transition: all 0.3s ease-out;
    @media only screen and (min-width: $tablet) {
        overflow-x: hidden;
        background-image: url("../../../assets/bg-el.svg");
        background-size: 2000px;
        background-repeat: no-repeat;
        margin: 2rem auto 0;
    }
    @media only screen and (min-width: $desktop-sm) {
        background-size: 1850px;
    }
    @media only screen and (min-width: $desktop-med) {
        background-size: 2000px;
    }

    @media only screen and (min-width: $desktop-xl) {
        background-size: 2000px;
        background-repeat: no-repeat;
        width: 2000px;
        margin: 2rem auto 0;
    }
}
