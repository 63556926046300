@import "../../colors.scss";

.cart-dropdown-container {
    position: absolute;
    width: 240px;
    height: 340px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid $black;
    background-color: white;
    top: 150px;
    right: 40px;
    z-index: 5;
    transition: all 1s ease-in-out;

    .empty-message {
        font-size: 18px;
        margin: 50px auto;
    }

    .cart-items {
        height: 240px;
        display: flex;
        flex-direction: column;
        overflow: scroll;
    }

    button {
        margin-top: auto;
    }
}

.checkout-link {
    text-decoration: none;
    color: $white;
    z-index: 5;
    &:hover {
        color: $black;
    }
}
