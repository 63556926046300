@import "../../../variables.scss";
@import "../../../colors.scss";

.giraffy-icon {
    width: 100px;
    height: 200px;
    position: absolute;
    top: -200px;
    left: 0;
    background-image: url(../../../assets/giraffy-icon.svg);
    background-repeat: no-repeat;
}

.footer-container {
    border-top: 8px solid $flame;
    background-color: $mine-shaft;
    width: 100%;
    display: flex;
    padding: 0 5%;
    box-sizing: border-box;
}

.footer-bg {
    display: none;
    @media only screen and (min-width: $desktop-sm) {
        display: flex;
        background-image: url(../../../assets/footer-bg-img.svg);
        background-repeat: no-repeat;
        position: absolute;
        right: 0;
        z-index: -1;
        width: 1500px;
        height: 218px;
        bottom: 20px;
    }
    @media only screen and (min-width: $desktop-sm) {
        margin-right: -20px;
    }
}

.footer-links-container {
    margin-bottom: 100px;
    @media only screen and (min-width: $desktop-sm) {
        margin-bottom: 98px;
    }
}

.footer-content-wrapper {
    width: 100%;
    position: relative;
    margin: 0 auto;
    flex-direction: row;
    display: flex;
    z-index: 1;
    @media only screen and (min-width: $desktop-sm) {
        width: 1600px;
    }
}

.footer-logo {
    background-image: url(../../../assets/footer-logo.svg);
    background-repeat: no-repeat;
    width: 100%;
    max-width: 100px;
    margin: 40px 0;
    @media only screen and (min-width: $desktop-sm) {
        width: 100px;
        margin: 36px 0;
    }
}

.footer-brand-title {
    color: $flame;
    font-size: 30px;
    display: flex;
    @media only screen and (min-width: $desktop-sm) {
        font-size: 48px;
    }

    p {
        margin-top: 64px;
        margin-bottom: 10px;
    }
}

.footer-links-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media only screen and (min-width: $desktop-sm) {
        width: 700px;
        flex-direction: row;
    }
}
.footer-nav-links {
    display: flex;
    flex-direction: row;
    color: $flame;
    width: 245px;
    flex-wrap: wrap;
    @media only screen and (min-width: $desktop-sm) {
        width: 100%;
    }

    .footer-nav-link {
        padding: 8px 10px;
    }
}

.footer-hr-verticle {
    display: none;

    @media only screen and (min-width: $desktop-sm) {
        height: 20px;
        width: 1px;
        display: flex;
    }
}

.social-links {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 245px;
    margin-left: 20px;
    margin-top: 10px;
    @media only screen and (min-width: $desktop-sm) {
        width: 100%;
        margin-left: 40px;
        margin-top: 0;
    }
}

.social-icon {
    width: 40px;
    height: 40px;
    background-image: url(../../../assets/twitter.svg);
    background-repeat: no-repeat;
    padding: 0px 10px;
    margin-top: 15px;
    @media only screen and (min-width: $desktop-sm) {
        width: 40px;
        height: 40px;
        margin-top: 0;
    }
}

.linkedin {
    padding-right: 0;
}

.footer-copyright-container {
    width: 100%;
    background-color: $laser-lemon;
    margin: 0;
    padding: 0 5%;
    box-sizing: border-box;
}

.footer-copyright {
    width: 100%;
    background-color: $laser-lemon;
    padding: 10px 0;
    max-width: 1600px;
    margin: 0 auto;
    p {
        margin: 0;
        color: $flame;
    }
}
