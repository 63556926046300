/* ============================================= */
/*         Nav Bar Styles                        */
/* ============================================= */

@import "../../../variables.scss";
@import "../../../colors.scss";

@keyframes dropDown {
    0% {
        transform: scaleY(0);
        transform-origin: top;
        opacity: 0;
    }
    100% {
        opacity: 1;
        transform: scaleY(1);
        transform-origin: top;
    }
}

@keyframes slideIn {
    from {
        transform: translateX(-70%);
    }
    to {
        transform: translateX(0%);
    }
}

.navigation-mobile {
    display: flex;
    width: 100%;
    padding: 20px 0 0;
    max-width: 100vw;
    align-items: center;
    @media only screen and (min-width: $desktop-sm) {
        display: none;
        height: 200px;
    }
}

.logo {
    margin-left: 10%;
    margin-right: auto;
    width: 220px;
    @media only screen and (min-width: $tablet) {
        width: 300px;
    }
}

.nav-link {
    display: inline-flex;
    padding: 0 0.8rem;
    text-decoration: none;
    color: $mine-shaft;
    font-family: $font-primary;
    &:hover {
        color: $flame;
        transition: all 0.4s ease-in-out;
    }
}

.hamburger-react {
    display: flex;
    margin-left: auto;
    margin-right: 10%;
}

.nav-links-container-mobile {
    position: absolute;
    right: 5%;
    top: 160px;
    z-index: 3;

    ul {
        background: linear-gradient(
            rgba(255, 255, 255, 0.037) 10%,
            rgba(145, 145, 145, 0.878)
        );
        border: solid 1px $flame;
        border-bottom: solid 20px $flame;
        animation: dropDown 0.6s forwards;
        padding: 15px;
        text-align: right;
        transition: all 0.4s ease-in-out;
        li {
            list-style: none;
            font-size: 34px;
            padding-top: 10px;
        }
        :first-child {
            transform: translateX(-70%);
            animation: slideIn 0.8s forwards 0.5s;
        }
        :nth-child(2) {
            transform: translateX(-70%);
            animation: slideIn 0.6s forwards 0.4s;
        }
        :nth-child(3) {
            transform: translateX(-70%);
            animation: slideIn 0.7s forwards 0.2s;
        }
        :last-child {
            transform: translateX(-70%);
            animation: slideIn 0.4s forwards 0.1s;
        }
    }
}
