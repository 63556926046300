@import "../../../variables.scss";
@import "../../../colors.scss";

.sign-in-container {
    margin: 0 auto 500px;
    max-width: 1600px;
    height: 900px;
    @media only screen and (min-width: $tablet) {
        margin: 60px auto 200px;
        background-image: url(../../../assets/sign-up-bg-img.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center -80px;
    }
}

.sign-in-form-wrapper {
    display: flex;
    max-width: 90%;
    border: 1px solid $torea-bay;
    background-color: $white;
    margin: 50px auto 0;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    box-shadow: 10px 20px 0px $magenta;
    @media only screen and (min-width: $tablet) {
        max-width: 800px;
        flex-direction: row;
        margin: auto auto 300px;
        box-shadow: 25px 35px 0px $magenta;
    }
    hr {
        width: 80%;
        margin: auto auto;
        border: 0;
        height: 2px;
        background: $flame;
        @media only screen and (min-width: $tablet) {
            width: 2px;
            height: 200px;
        }
    }
}
